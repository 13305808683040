import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  message,
  Button,
  Divider,
  Form,
  Input,
  Row,
  Col,
  Card
} from 'antd';

import { ApiCallGet } from '../helpers/ApiCallGet';
import AppLayout from '../components/AppLayout';
import { Context } from '../context';

function PasswordReset(props) {

  const [messageApi, contextHolder] = message.useMessage();
  const [disabled, setDisabled] = useState(false);
  const [form] = Form.useForm();

  const {reference, referral, appToken } = useContext(Context);
  const navigate = useNavigate();


  const error = (e) => {
    messageApi.open({
      type: 'error',
      content: e,
    });
  };
   const load = () => {
    messageApi.open({
      type: 'loading',
      content: 'Invio richiesta di recupero password...',
      duration: 0,
    });
  };


  const resetPassword = (values) => {

   load();

    (async () => {
      const res = await ApiCallGet(`reset_password/?{"token_key":"${appToken}","email":"${values.email}"}`);
 
       if (res.status === "Ok") {
         setDisabled(false);
         messageApi.destroy();
         messageApi.open({
            type: 'success',
            content: 'Abbiamo inviato una mail di recupero password alla tua casella email! Controlla anche la cartella spam.',
            duration: 0,
         });
         setTimeout(() => {
            messageApi.destroy();
         }, 5000);
       } else {
         setDisabled(false);
         messageApi.destroy();
         error("Dati errati o utente non esistente");
         setTimeout(() => {
            messageApi.destroy();
         }, 5000);
       }
    })()

  };

  useEffect(()=>{
    if(referral) return;
    if(localStorage.getItem(reference)) navigate("/smart-card")
  },[reference])


  return (
    <AppLayout pageTitle={"Reset Password"}>
      <div className='w-100 flex justify-center align-center p-10'>
        <Card className='shadow-2 mt-20' bordered={true}>
            <Form
              form={form}
              name="reset-password"
              className='reset-password-form'
              onFinish={(e) => {
                resetPassword(e);
                setDisabled(!disabled);
              }}
              layout='vertical'
              scrollToFirstError
            >
              {contextHolder}
              {/* email */}
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'Il campo E-Mail non è valido',
                  },
                  {
                    required: true,
                    message: 'Inserire una E-mail',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* email */}
          
              <Form.Item>
                <Button disabled={disabled} shape='round' type="primary" block size='large' className='fs-20 bold mt-20' htmlType="submit">
                  Richiedi Reset
                </Button>
              </Form.Item>
            </Form>

          <Row gutter={[10,10]} justify={"space-between"} className='mt-20'>
            <Col>
              <div className='w-100 text-center fs-15'> 
                <span>Non hai un account? </span>
                <a 
                  className='bold' 
                  onClick={() => {
                    navigate(`/register?webhook=${referral}`);
                  }}
                >
                  Registrati
                </a>
              </div>
            </Col>
                 <Divider type='vertical'/>
            <Col>
              <div className='w-100 text-center fs-15'> 
                <span>Hai già un Account? </span>
                <a 
                  className='bold' 
                  onClick={() => {
                    navigate(`/login?webhook=${referral}`);
                  }}
                >
                  Accedi
                </a>
              </div>
            </Col>
          </Row>
           
        </Card>
      </div>
    </AppLayout>
  );
}

export default PasswordReset;